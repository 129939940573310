html {
  height: 100%;
  background-color: #000;
}

.app {
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  width: calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right));
}

#root, .app {
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  width: calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right));
}

.app {
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

#root {
  height: 100%;
}

#root, .app {
  width: 100%;
}

.app-wrapper {
  display: flex;
  flex-direction: row;
  max-width: 750px;
  justify-content: space-between;
  align-items: center;
  margin: 24px;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.app-info-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.app-social-wrapper {
  border: 1px solid #A392FA;
  border-radius: 30px;
  width: 125px;
  padding: 8px 16px 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.divider {
  height: 19px;
  width: 1px;
  background: #A392FA;
}

.reserved {
  border: 1px solid #A392FA;
  color: #A392FA;
  border-radius: 30px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  bottom: 16px;
  font-size: 12px;
  width: 60%;
  max-width: 600px;
  margin: 0 auto;
}


@keyframes flyStar {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(calc(100vw - 50px), calc(100vh - 50px));
  }
}

.star {
  position: fixed;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  rotate: 90%;
}

.fly {
  animation: flyStar 3s linear;
}

.whitePaperBtn {
  width: 304px;
  height: 60px;
  background-color: #CFC6FF;
  color: #000;
  font-weight: 600;
  font-size: 24px;
  border-radius: 28px;
  margin: 0 auto;
  cursor: pointer;
  border: 0;
  font-family: "Archivo", sans-serif !important;
}

@media (max-width: 680px) {
  .whitePaperBtn  {
    height: 40px;
    width: 250px;
  }
}



@media screen and (max-width: 450px) {
  .app-info-area > img {
    width: 250px!important;
  }
}

.privacy-policy-container,
.terms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: white;
  border-radius: 20px;
  margin-top: 2rem;
  max-height: 80vh;
  overflow-y: auto;
}

.privacy-content,
.terms-content {
  padding: 1rem;
  margin: 1rem 0;
}

.privacy-content h1,
.terms-content h1 {
  font-size: 70px;
  color: #A392FA;
  margin-bottom: 2rem;
  font-family: "Archivo", sans-serif;
  font-weight: 700;
  text-shadow: 0 0 10px rgba(163, 146, 250, 0.3);
}

.privacy-content h2,
.terms-content h2 {
  color: #A392FA;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: "Archivo", sans-serif;
  font-size: 26px;
  font-weight: 700;
  text-shadow: 0 0 8px rgba(163, 146, 250, 0.2);
  border-bottom: 2px solid rgba(163, 146, 250, 0.3);
  padding-bottom: 0.5rem;
}

.privacy-content h3,
.terms-content h3 {
  color: #A392FA;
  margin-top: 1.5rem;
  margin-bottom: 0.8rem;
  font-family: "Archivo", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-shadow: 0 0 5px rgba(163, 146, 250, 0.1);
}

.privacy-content p,
.terms-content p {
  line-height: 1.6;
  margin-bottom: 1rem;
  color: #fff;
  font-size: 16px;
}

.privacy-content ul,
.terms-content ul {
  margin-left: 1.5rem;
  margin-bottom: 0.8rem;
  color: #fff;
  list-style-type: disc;
  padding-left: 0.5rem;
}

.privacy-content ul li,
.terms-content ul li {
  margin-bottom: 0.3rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
}

.back-button {
  background: rgba(163, 146, 250, 0.1);
  border: 1px solid #A392FA;
  color: #A392FA;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "Archivo", sans-serif;
  margin-top: 1rem;
}

.back-button:hover {
  background: rgba(163, 146, 250, 0.2);
}

.privacy-policy-container::-webkit-scrollbar,
.terms-container::-webkit-scrollbar {
  width: 8px;
}

.privacy-policy-container::-webkit-scrollbar-track,
.terms-container::-webkit-scrollbar-track {
  background: rgba(163, 146, 250, 0.1);
  border-radius: 4px;
}

.privacy-policy-container::-webkit-scrollbar-thumb,
.terms-container::-webkit-scrollbar-thumb {
  background: #A392FA;
  border-radius: 4px;
}

.privacy-policy-container::-webkit-scrollbar-thumb:hover,
.terms-container::-webkit-scrollbar-thumb:hover {
  background: rgba(163, 146, 250, 0.8);
}

@media (max-width: 768px) {
  .privacy-policy-container,
  .terms-container {
    margin: 1rem;
    padding: 1rem;
  }
  
  .privacy-content h1,
  .terms-content h1 {
    font-size: 40px;
    text-shadow: 0 0 8px rgba(163, 146, 250, 0.2);
  }
  
  .privacy-content h2,
  .terms-content h2 {
    font-size: 22px;
    text-shadow: 0 0 6px rgba(163, 146, 250, 0.15);
  }
  
  .privacy-content h3,
  .terms-content h3 {
    font-size: 18px;
    text-shadow: 0 0 4px rgba(163, 146, 250, 0.1);
  }

  .privacy-content p,
  .terms-content p,
  .privacy-content ul li,
  .terms-content ul li {
    font-size: 14px;
  }

  .privacy-content ul,
  .terms-content ul {
    margin-left: 1.2rem;
    margin-bottom: 0.6rem;
  }
  
  .privacy-content ul li,
  .terms-content ul li {
    margin-bottom: 0.25rem;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .privacy-content h1,
  .terms-content h1 {
    font-size: 32px;
    text-shadow: 0 0 6px rgba(163, 146, 250, 0.15);
  }
  
  .privacy-content h2,
  .terms-content h2 {
    font-size: 20px;
    text-shadow: 0 0 4px rgba(163, 146, 250, 0.1);
  }
  
  .privacy-content h3,
  .terms-content h3 {
    font-size: 16px;
    text-shadow: 0 0 3px rgba(163, 146, 250, 0.1);
  }
}

.footer-links {
  display: flex;
  gap: 8px;
  align-items: center;
}

.footer-links a {
  color: #A392FA;
  text-decoration: none;
  font-size: 12px;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #CFC6FF;
}

.footer-links span {
  color: #A392FA;
  font-size: 12px;
}

@media (max-width: 375px) {
  .footer-links a,
  .footer-links span {
    font-size: 10px;
  }
  
  .footer-links {
    gap: 6px;
  }
}